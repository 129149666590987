import { stripTags } from '@zicht/nuxt-util'
import { getMediaSeoImage } from '~/assets/media'

export function getIndirectClientHeaders({ req }) {
  return process.server
    ? {
        'client-ip': req.headers['x-real-ip'] || req.headers['x-forwarded-for'],
        'user-agent': req.headers['user-agent'],
        referer: req.headers.referer,
      }
    : {}
}

function getHomeUrl(page) {
  const match = page?.url.match(/^(https?):\/\/([^/]+)/i)
  return match ? `${match[1]}://${match[2]}/` : undefined
}

function generateSeoEventTitle(title, seoGlobal) {
  return `${title} - ${seoGlobal?.siteName}`
}

export const seoHead = (page, seoGlobal) => {
  const pageImage = getMediaSeoImage(page?.image || page?.production?.image)
  const pageDescription = stripTags(page?.introduction || page?.description || page?.production?.introduction || '')
  // only apply the seo title overrides for events (concerten)
  const twitterTitle = page?.__typename === 'event_default_Entry' ? generateSeoEventTitle(page?.title, seoGlobal) : page?.seo?.social.twitter.title
  const facebookTitle = page?.__typename === 'event_default_Entry' ? generateSeoEventTitle(page?.title, seoGlobal) : page?.seo?.social.facebook.title

  const meta = [
    { name: 'description', content: page?.seo?.description || pageDescription },
    { name: 'keywords', content: page?.seo?.keywords.map(({ keyword }) => keyword).filter((keyword) => keyword) },

    // Robots
    // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
    { name: 'robots', content: page?.seo?.advanced.robots },
    { name: 'robots', content: page?.expiryDate ? `unavailable_after: ${page.expiryDate}` : undefined },
    // if content values conflict, the most restrictive will be used
    // https://developers.google.com/search/blog/2007/03/using-robots-meta-tag#multiple-content-values
    { name: 'robots', content: page?.concertStatus === 'Prive' ? 'noindex' : undefined },

    // Twitter
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: seoGlobal?.twitterhandle },
    { name: 'twitter:url', content: page?.url },
    { name: 'twitter:title', content: twitterTitle },
    { name: 'twitter:description', content: page?.seo?.social.twitter.description || pageDescription },
    { name: 'twitter:image', content: page?.seo?.social.twitter.image?.url || pageImage.url },

    // Facebook
    { property: 'fb:app_id', content: seoGlobal?.facebookHandle },
    { property: 'og:url', content: page?.url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: facebookTitle },
    { property: 'og:image', content: page?.seo?.social.facebook.image?.url || pageImage.url },
    { property: 'og:image:width', content: page?.seo?.social.facebook.image?.width || pageImage.width },
    { property: 'og:image:height', content: page?.seo?.social.facebook.image?.height || pageImage.height },
    { property: 'og:description', content: page?.seo?.social.facebook.description || pageDescription },
    { property: 'og:site_name', content: seoGlobal?.siteName },
    { property: 'og:locale', content: page?.language },
    // { property: 'og:locale:alternate', content: 'en' },
  ]

  const link = [
    { rel: 'home', href: getHomeUrl(page) },
    { rel: 'canonical', href: page?.seo?.advanced.canonical || page?.url },
    {
      rel: 'alternate',
      href: (page.alternativeLanguages || [])
        .filter(({ url }) => url) // prevents crash in case url is empty (for example when site is disabled)
        .filter(({ language }) => language === 'en')
        .map(({ url }) => url),
      hreflang: 'x-default',
    },
    ...(page.alternativeLanguages || []).map(({ url, language }) => ({
      rel: 'alternate',
      href: url === page?.url ? page?.seo?.advanced.canonical || page?.url : url,
      hreflang: language,
    })),
  ]

  // https://github.com/ethercreative/seo/blob/v3/src/templates/_seo/meta.twig
  return {
    htmlAttrs: {
      lang: page?.language || 'nl',
    },
    title: page?.seo?.title,
    meta: meta.filter(({ content }) => content),
    link: link.filter(({ href }) => href),
  }
}
